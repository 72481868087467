import { BaseService, Service } from "/@/cool";
@Service("repairSkuPlan")
class ModelRepairTeam extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}
		this.permission["add"] = prefix + "add";
		this.permission["update"] = prefix + "update";
		this.permission["page"] = prefix + "page";
		this.permission["list"] = prefix + "list";
		this.permission["info"] = prefix + "info";
		this.permission["delete"] = prefix + "delete";
		this.permission["bindData"] = prefix + "bindData";
		this.permission["getBindData"] = prefix + "getBindData";
		this.permission["copyPlan"] = prefix + "copyPlan";
		this.permission["planList"] = prefix + "planList";
		this.permission["getHasBindMember"] = prefix + "getHasBindMember";
	}
	bindData(data: any) {
		return this.request({
			url: "/bindData",
			method: "POST",
			data
		});
	}
	getBindData(data: any) {
		return this.request({
			url: "/getBindData",
			method: "POST",
			data
		});
	}
	copyPlan(data: any) {
		return this.request({
			url: "/copyPlan",
			method: "POST",
			data
		});
	}
	planList(data: any) {
		return this.request({
			url: "/planList",
			method: "POST",
			data
		});
	}
	getHasBindMember(data: any) {
		return this.request({
			url: "/getHasBindMember",
			method: "POST",
			data
		});
	}
}
export default ModelRepairTeam;
