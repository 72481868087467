import { BaseService, Service } from "/@/cool";
@Service("repairSettingBuild")
class ModelRepairTeam extends BaseService {
	constructor() {
		super();

		let prefix = "";
		if (this.namespace) {
			prefix = this.namespace.replace(/\//g, ":") + ":";
		}
		this.permission["cols"] = prefix + "cols";
		this.permission["save"] = prefix + "save";
	}
	cols(data: any) {
		return this.request({
			url: "/cols",
			method: "POST",
			data
		});
	}
	save(data: any) {
		return this.request({
			url: "/save",
			method: "POST",
			data
		});
	}
}
export default ModelRepairTeam;
