import { ModuleConfig } from "/@/cool";

export default (): ModuleConfig => {
	return {
		components: [
			import("./components/area/address.vue"),
			import("./components/avatar/index.vue"),
			import("./components/button/cmd.vue"),
			import("./components/button/readytake.vue"),
			import("./components/button/shutdown.vue"),
			import("./components/codemirror/index.vue"),
			import("./components/date/datepicker.vue"),
			import("./components/date/text.vue"),
			// dailog
			import("./components/dialog/add-goods.vue"),
			import("./components/dialog/customer-service.vue"),
			import("./components/dialog/model-rebuild-dialog.vue"),
			import("./components/dialog/model-upload.vue"),
			import("./components/dialog/order-dialog.vue"),
			import("./components/dialog/order-goods.vue"),
			import("./components/dialog/out-url.vue"),
			import("./components/dialog/preview-image.vue"),
			import("./components/dialog/preview.vue"),
			import("./components/dialog/repair-check.vue"),
			import("./components/dialog/reprint-order-picker.vue"),
			import("./components/dialog/reserver-print-order-picker.vue"),
			import("./components/dialog/sence-preview-image.vue"),
			import("./components/dialog/settle-log.vue"),
			import("./components/dialog/shop.vue"),
			import("./components/dialog/takephoto-order.vue"),
			import("./components/editor-quill/index.vue"),
			import("./components/icon/svg.vue"),
			import("./components/icon-svg/index.vue"),
			// image
			import("./components/image/index.vue"),
			import("./components/image/securet.vue"),
			import("./components/image/securet-list.vue"),
			import("./components/image/service-image.vue"),
			import("./components/image/texture.vue"),
			import("./components/image/watermask.vue"),
			import("./components/qrcode/qrcode.vue"),
			import("./components/query/query.vue"),
			import("./components/query-priority/order.vue"),
			import("./components/query-priority/priority.vue"),
			import("./components/scrollbar/index.vue"),
			import("./components/select/area.vue"),
			import("./components/select/attribute-multi.vue"),
			import("./components/select/attribute.vue"),
			import("./components/select/china-area.vue"),
			import("./components/select/customer.vue"),
			import("./components/select/equipment.vue"),
			import("./components/select/fans.vue"),
			import("./components/select/model_repair_item.vue"),
			import("./components/select/model_repair_level.vue"),
			import("./components/select/model_repair_team.vue"),
			import("./components/select/shop.vue"),
			import("./components/select/user.vue"),
			import("./components/select/wechat-menu.vue"),
			import("./components/select/size.vue"),
			import("./components/select/style.vue"),
			import("./components/select/product.vue"),
			import("./components/swiper/swiper.vue"),
			import("./components/table/order_goods_table.vue"),
			import("./components/tag/buy-type.vue")
		]
	};
};
